import React from 'react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import { Link } from 'gatsby';
// import { List, ThemeIcon } from '@mantine/core';
import PageHeader from '../../components/PageHeader';
// import { CircleDashed } from 'tabler-icons-react';
// import ClientStripe from '../../assets/images/circle-stripe.png';
import Footer from '../../components/Footer';
// import { Link } from '../../components/client-access';

const Content = () => {
  return (
    <div>
      <section className='contact-info-one'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='contact-info-one__single'>
                <div className='contact-info-one__icon'>
                  <i className='kipso-icon-manager'></i>
                </div>
                <h2 className='contact-info-one__title'>About Us </h2>

                <p className='contact-info-one__text'>
                  <br />{' '}
                  <Link to='../complaints-servicom-charter'>Our Charter</Link>
                  {/* available in the market to use */}
                  <br />
                  <br />
                  <br />
                  {/* deliver satisfaction. */}
                </p>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='contact-info-one__single'>
                <div className='contact-info-one__icon'>
                  <i className='kipso-icon-health'></i>
                </div>
                <h2 className='contact-info-one__title'>
                  CLIENTS’ ACCESS TO LUTH FACILITIES & SERVICES
                </h2>
                <p className='contact-info-one__text'>
                  <br />{' '}
                  <Link to='../ClientAccess'>
                    Client's Access
                  </Link>
                  {/* available in the market to use */}
                  <br />
                  {/* <br /> */}
                  <br />
                  {/* deliver satisfaction. */}
                </p>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='contact-info-one__single'>
                <div className='contact-info-one__icon'>
                  <i className='kipso-icon-contact'></i>
                </div>
                <h2 className='contact-info-one__title'>Contact Info</h2>
                <p className='contact-info-one__text'>
                  servicom@luth.gov.ng
                  <br />
                  Servicom Desk Officer: 0803 648 9497
                  <br />
                  Nodal Officer: 0705 259 9941 <br /> &nbsp;
                </p>
              </div>
            </div>
            {/* <div className='col-lg-4'>
              <div className='contact-info-one__single'>
                <div className='contact-info-one__icon'>
                  <i className='kipso-icon-email'></i>
                </div>
                <h2 className='contact-info-one__title'>For Complaints</h2>
                <p className='contact-info-one__text'>
                  servicom@luth.gov.ng
                  <br />
                  Servicom Desk Officer: 0803 648 9497
                  <br />
                  Nodal Officer: 0705 259 9941 <br /> &nbsp;
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <section className='contact-one'>
        <div className='container'>
          <h2 className='contact-one__title text-center'>
            Get in touch <br />
            with us
          </h2>
          <form
            action='#'
            className='contact-one__form contact-form-validated'
            noValidate='novalidate'
          >
            <div className='row low-gutters'>
              <div className='col-lg-6'>
                <input type='text' name='name' placeholder='Your Name' />
              </div>
              <div className='col-lg-6'>
                <input type='text' placeholder='Email Address' name='email' />
              </div>
              <div className='col-lg-6'>
                <input type='text' name='department' placeholder='Department' />
              </div>
              <div className='col-lg-6'>
                <input
                  type='number'
                  placeholder='Phone Number'
                  name='phonenumber'
                />
              </div>
              <div className='col-lg-12'>
                <textarea placeholder='Write Message' name='message'></textarea>
                <div className='text-center'>
                  <button type='submit' className='contact-one__btn thm-btn'>
                    Submit Comment 
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div className='result text-center'></div>
        </div>
      </section>
      <iframe
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.0321759804956!2d3.3515861148696575!3d6.5176112952855805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8c33f430d20b%3A0x63a70ffffe080bee!2sLagos%20University%20Teaching%20Hospital!5e0!3m2!1sen!2sng!4v1646647433154!5m2!1sen!2sng'
        className='google-map__contact'
        title='template google map'
        allowFullScreen=''
        loading='lazy'
      ></iframe>
    </div>
  );
};

const AboutPage = () => {
  return (
    <Layout pageTitle='Clinics | LUTH'>
      <NavOne />
      <PageHeader title='' />
      <Content />
      <Footer />
    </Layout>
  );
};

export default AboutPage;
